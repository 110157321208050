/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Fonts */

@font-face {
  font-family: "Halifax";
  src: url("assets/fonts/Halifax/HalLig.otf")format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Halifax";
  src: url("assets/fonts/Halifax/HalLigIta.otf")format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Halifax";
  src: url("assets/fonts/Halifax/HalReg.otf")format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Halifax";
  src: url("assets/fonts/Halifax/HalIta.otf")format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Halifax";
  src: url("assets/fonts/Halifax/HalMed.otf")format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Halifax";
  src: url("assets/fonts/Halifax/HalMedIta.otf")format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Halifax";
  src: url("assets/fonts/Halifax/HalXBol.otf")format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Halifax";
  src: url("assets/fonts/Halifax/HalXBolIta.otf")format('opentype');
  font-weight: 800;
  font-style: italic;
}

html {
  --ion-dynamic-font: var(--ion-default-dynamic-font);
  --ion-font-family: 'Halifax';
}

ion-nav-link {
  width: 100%;
}

ion-button.ios {
  height: 100%;
}

ion-button {
  margin: 15px 0;
  letter-spacing: 2px;
}

ion-grid {
  padding-left: 0;
  padding-right: 0;
}

ion-menu ion-content {
  --background: var(--ion-color-step-950, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 20px;
  --padding-end: 0;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-label {
  font-weight: 400;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 20px;
  --padding-end: 0;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

ion-menu {
  --min-width: 100%;
}

.header {
  height: 65px;
  display: flex;
  align-items: center;
}

/*.ferragamo-button {
  border-radius: 5px;
  width: 100%;
  height: 50px;
  font-size: 1.5rem;
  letter-spacing: 4px;
  text-transform: none;
  --border-radius: 5;
  font-weight: 400;
  margin: 5px 0;
}*/

.ferragamo-button {
  width: 100%;
  min-height: 50px;
  font-size: 1.1rem;
  text-transform: none;
  border: 2px solid var(--ion-color-primary);
  border-radius: 5px;
  font-weight: 400;
  margin: 5px 0;
  padding: 1px;
  --background: var(--ion-color-light);
  --color: black;
  --box-shadow:none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
}

.button-white {
  width: 100%;
  min-height: 50px;
  font-size: 1rem;
  text-transform: none;
  --background: var(--ion-color-light);
  --color: black;
}

a {
  color: black;
}

.image-container {
  position: relative;
  //height: 55vh;
  border-bottom: solid var(--ion-color-primary) 4px;
  min-height: 200px;

  #main-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.video-container {
  position: relative;
  border-bottom: solid var(--ion-color-primary) 4px;
}
.video-full-container {
  position: relative;
  border-bottom: solid var(--ion-color-primary) 4px;
}

.title-container {
  text-align: center;
  position: absolute;
  top: 50%;
  left:50%;
  min-width: 300px;
  transform: translateX(-50%) translateY(-50%);
  margin: auto;
  padding: 0 15px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  h4 {
    color: white;
    font-weight: 400;
    margin: 0;
  }

  h1 {
    margin-top: 0;
  }
}

p {
  line-height: 25px;
  font-size: 1.1rem;
}

h2 {
  font-weight: 400;
}

b {
  font-weight: 500;
}

.description-container {
  margin-bottom: 40px;
  p {
    text-align: justify;
    line-height: 1.8rem;
  }
  
}

.main-page {
  text-transform: uppercase;
  font-size: 2rem;
}

.other-page {
  font-size: 1.2rem;
}

.limit-content {
  padding: 0 1rem;
  margin: auto;
}

@media only screen and (min-width: 769px) {
  .limit-content {
    max-width: 1024px;
    margin: auto;
  }
  .image-container {
    //height: 100%;
    max-width: 1024px;
    margin: auto;
  }
  .video-container {
    max-height: 580px;
    max-width: 1024px;
    margin: auto;
  }
  .video-full-container {
    max-height: 100%;
    max-width: 1024px;
    margin: auto;
  }

  .button-container {
    display: flex;
    gap: 75px;
    flex-wrap: wrap;
    justify-content: center;
    .ferragamo-button {
      width: 250px;
    }
  }

  
}